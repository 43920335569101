import { default as ai_45form_45builderK2bQtGZEnKMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/ai-form-builder.vue?macro=true";
import { default as editKvb39g9kS1Meta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexQsPEXuiQJcMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/forms/[slug]/index.vue?macro=true";
import { default as indexjLmbThP3BxMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as sharer1YhjU5AAOMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as statsKlJMylCPSXMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/forms/[slug]/show/stats.vue?macro=true";
import { default as submissionsx6DXgp8ShIMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/forms/[slug]/show/submissions.vue?macro=true";
import { default as showgiallCIS1gMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/forms/[slug]/show.vue?macro=true";
import { default as guestYHo4wqwWVWMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/forms/create/guest.vue?macro=true";
import { default as index7XMWJeaH9tMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/forms/create/index.vue?macro=true";
import { default as homen52FT962b2Meta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/home.vue?macro=true";
import { default as indexeuBwHuwRAZMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/index.vue?macro=true";
import { default as loginYSBUSo4nuBMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/login.vue?macro=true";
import { default as emailR2sY2sgZ68Meta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/password/email.vue?macro=true";
import { default as _91token_93HD328aR5h2Meta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/password/reset/[token].vue?macro=true";
import { default as pricingWpTvaN00WxMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/pricing.vue?macro=true";
import { default as privacy_45policyD4FvNIIKEXMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/privacy-policy.vue?macro=true";
import { default as register6G8llQDNrYMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/register.vue?macro=true";
import { default as accountybZoRDwRD7Meta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/settings/account.vue?macro=true";
import { default as adminYdiVP2AYE6Meta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/settings/admin.vue?macro=true";
import { default as billingHzX4K70mXOMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/settings/billing.vue?macro=true";
import { default as indexpw4wsIMI0DMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/settings/index.vue?macro=true";
import { default as passwordFWPO3tkT1HMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/settings/password.vue?macro=true";
import { default as profileD4FbEFy1pYMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/settings/profile.vue?macro=true";
import { default as workspaceheIukYEDIwMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/settings/workspace.vue?macro=true";
import { default as settingsOB9tC6yN2hMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/settings.vue?macro=true";
import { default as errorzIRURwTpZDMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/subscriptions/error.vue?macro=true";
import { default as successwz2bF1K5X3Meta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/subscriptions/success.vue?macro=true";
import { default as _91slug_93QcN0934MTjMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/templates/[slug].vue?macro=true";
import { default as indexlAeyeUblmFMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/templates/index.vue?macro=true";
import { default as _91slug_939xlOoJAIZCMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/templates/industries/[slug].vue?macro=true";
import { default as my_45templatesOV8yl5mnnvMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/templates/my-templates.vue?macro=true";
import { default as _91slug_93w6vX48wPqZMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/templates/types/[slug].vue?macro=true";
import { default as terms_45conditionsnPTtvoX5ogMeta } from "/var/www/formbuilder.uptodateconnect.com/client/pages/terms-conditions.vue?macro=true";
export default [
  {
    name: ai_45form_45builderK2bQtGZEnKMeta?.name ?? "ai-form-builder",
    path: ai_45form_45builderK2bQtGZEnKMeta?.path ?? "/ai-form-builder",
    meta: ai_45form_45builderK2bQtGZEnKMeta || {},
    alias: ai_45form_45builderK2bQtGZEnKMeta?.alias || [],
    redirect: ai_45form_45builderK2bQtGZEnKMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/ai-form-builder.vue").then(m => m.default || m)
  },
  {
    name: editKvb39g9kS1Meta?.name ?? "forms-slug-edit",
    path: editKvb39g9kS1Meta?.path ?? "/forms/:slug()/edit",
    meta: editKvb39g9kS1Meta || {},
    alias: editKvb39g9kS1Meta?.alias || [],
    redirect: editKvb39g9kS1Meta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexQsPEXuiQJcMeta?.name ?? "forms-slug",
    path: indexQsPEXuiQJcMeta?.path ?? "/forms/:slug()",
    meta: indexQsPEXuiQJcMeta || {},
    alias: indexQsPEXuiQJcMeta?.alias || [],
    redirect: indexQsPEXuiQJcMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showgiallCIS1gMeta?.name ?? undefined,
    path: showgiallCIS1gMeta?.path ?? "/forms/:slug()/show",
    meta: showgiallCIS1gMeta || {},
    alias: showgiallCIS1gMeta?.alias || [],
    redirect: showgiallCIS1gMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: indexjLmbThP3BxMeta?.name ?? "forms-slug-show",
    path: indexjLmbThP3BxMeta?.path ?? "",
    meta: indexjLmbThP3BxMeta || {},
    alias: indexjLmbThP3BxMeta?.alias || [],
    redirect: indexjLmbThP3BxMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: sharer1YhjU5AAOMeta?.name ?? "forms-slug-show-share",
    path: sharer1YhjU5AAOMeta?.path ?? "share",
    meta: sharer1YhjU5AAOMeta || {},
    alias: sharer1YhjU5AAOMeta?.alias || [],
    redirect: sharer1YhjU5AAOMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  },
  {
    name: statsKlJMylCPSXMeta?.name ?? "forms-slug-show-stats",
    path: statsKlJMylCPSXMeta?.path ?? "stats",
    meta: statsKlJMylCPSXMeta || {},
    alias: statsKlJMylCPSXMeta?.alias || [],
    redirect: statsKlJMylCPSXMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/forms/[slug]/show/stats.vue").then(m => m.default || m)
  },
  {
    name: submissionsx6DXgp8ShIMeta?.name ?? "forms-slug-show-submissions",
    path: submissionsx6DXgp8ShIMeta?.path ?? "submissions",
    meta: submissionsx6DXgp8ShIMeta || {},
    alias: submissionsx6DXgp8ShIMeta?.alias || [],
    redirect: submissionsx6DXgp8ShIMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/forms/[slug]/show/submissions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: guestYHo4wqwWVWMeta?.name ?? "forms-create-guest",
    path: guestYHo4wqwWVWMeta?.path ?? "/forms/create/guest",
    meta: guestYHo4wqwWVWMeta || {},
    alias: guestYHo4wqwWVWMeta?.alias || [],
    redirect: guestYHo4wqwWVWMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/forms/create/guest.vue").then(m => m.default || m)
  },
  {
    name: index7XMWJeaH9tMeta?.name ?? "forms-create",
    path: index7XMWJeaH9tMeta?.path ?? "/forms/create",
    meta: index7XMWJeaH9tMeta || {},
    alias: index7XMWJeaH9tMeta?.alias || [],
    redirect: index7XMWJeaH9tMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: homen52FT962b2Meta?.name ?? "home",
    path: homen52FT962b2Meta?.path ?? "/home",
    meta: homen52FT962b2Meta || {},
    alias: homen52FT962b2Meta?.alias || [],
    redirect: homen52FT962b2Meta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexeuBwHuwRAZMeta?.name ?? "index",
    path: indexeuBwHuwRAZMeta?.path ?? "/",
    meta: indexeuBwHuwRAZMeta || {},
    alias: indexeuBwHuwRAZMeta?.alias || [],
    redirect: indexeuBwHuwRAZMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginYSBUSo4nuBMeta?.name ?? "login",
    path: loginYSBUSo4nuBMeta?.path ?? "/login",
    meta: loginYSBUSo4nuBMeta || {},
    alias: loginYSBUSo4nuBMeta?.alias || [],
    redirect: loginYSBUSo4nuBMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: emailR2sY2sgZ68Meta?.name ?? "password-email",
    path: emailR2sY2sgZ68Meta?.path ?? "/password/email",
    meta: emailR2sY2sgZ68Meta || {},
    alias: emailR2sY2sgZ68Meta?.alias || [],
    redirect: emailR2sY2sgZ68Meta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/password/email.vue").then(m => m.default || m)
  },
  {
    name: _91token_93HD328aR5h2Meta?.name ?? "password-reset-token",
    path: _91token_93HD328aR5h2Meta?.path ?? "/password/reset/:token()",
    meta: _91token_93HD328aR5h2Meta || {},
    alias: _91token_93HD328aR5h2Meta?.alias || [],
    redirect: _91token_93HD328aR5h2Meta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: pricingWpTvaN00WxMeta?.name ?? "pricing",
    path: pricingWpTvaN00WxMeta?.path ?? "/pricing",
    meta: pricingWpTvaN00WxMeta || {},
    alias: pricingWpTvaN00WxMeta?.alias || [],
    redirect: pricingWpTvaN00WxMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyD4FvNIIKEXMeta?.name ?? "privacy-policy",
    path: privacy_45policyD4FvNIIKEXMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyD4FvNIIKEXMeta || {},
    alias: privacy_45policyD4FvNIIKEXMeta?.alias || [],
    redirect: privacy_45policyD4FvNIIKEXMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: register6G8llQDNrYMeta?.name ?? "register",
    path: register6G8llQDNrYMeta?.path ?? "/register",
    meta: register6G8llQDNrYMeta || {},
    alias: register6G8llQDNrYMeta?.alias || [],
    redirect: register6G8llQDNrYMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsOB9tC6yN2hMeta?.name ?? undefined,
    path: settingsOB9tC6yN2hMeta?.path ?? "/settings",
    meta: settingsOB9tC6yN2hMeta || {},
    alias: settingsOB9tC6yN2hMeta?.alias || [],
    redirect: settingsOB9tC6yN2hMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: accountybZoRDwRD7Meta?.name ?? "settings-account",
    path: accountybZoRDwRD7Meta?.path ?? "account",
    meta: accountybZoRDwRD7Meta || {},
    alias: accountybZoRDwRD7Meta?.alias || [],
    redirect: accountybZoRDwRD7Meta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: adminYdiVP2AYE6Meta?.name ?? "settings-admin",
    path: adminYdiVP2AYE6Meta?.path ?? "admin",
    meta: adminYdiVP2AYE6Meta || {},
    alias: adminYdiVP2AYE6Meta?.alias || [],
    redirect: adminYdiVP2AYE6Meta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: billingHzX4K70mXOMeta?.name ?? "settings-billing",
    path: billingHzX4K70mXOMeta?.path ?? "billing",
    meta: billingHzX4K70mXOMeta || {},
    alias: billingHzX4K70mXOMeta?.alias || [],
    redirect: billingHzX4K70mXOMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: indexpw4wsIMI0DMeta?.name ?? "settings",
    path: indexpw4wsIMI0DMeta?.path ?? "",
    meta: indexpw4wsIMI0DMeta || {},
    alias: indexpw4wsIMI0DMeta?.alias || [],
    redirect: indexpw4wsIMI0DMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: passwordFWPO3tkT1HMeta?.name ?? "settings-password",
    path: passwordFWPO3tkT1HMeta?.path ?? "password",
    meta: passwordFWPO3tkT1HMeta || {},
    alias: passwordFWPO3tkT1HMeta?.alias || [],
    redirect: passwordFWPO3tkT1HMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: profileD4FbEFy1pYMeta?.name ?? "settings-profile",
    path: profileD4FbEFy1pYMeta?.path ?? "profile",
    meta: profileD4FbEFy1pYMeta || {},
    alias: profileD4FbEFy1pYMeta?.alias || [],
    redirect: profileD4FbEFy1pYMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: workspaceheIukYEDIwMeta?.name ?? "settings-workspace",
    path: workspaceheIukYEDIwMeta?.path ?? "workspace",
    meta: workspaceheIukYEDIwMeta || {},
    alias: workspaceheIukYEDIwMeta?.alias || [],
    redirect: workspaceheIukYEDIwMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/settings/workspace.vue").then(m => m.default || m)
  }
]
  },
  {
    name: errorzIRURwTpZDMeta?.name ?? "subscriptions-error",
    path: errorzIRURwTpZDMeta?.path ?? "/subscriptions/error",
    meta: errorzIRURwTpZDMeta || {},
    alias: errorzIRURwTpZDMeta?.alias || [],
    redirect: errorzIRURwTpZDMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: successwz2bF1K5X3Meta?.name ?? "subscriptions-success",
    path: successwz2bF1K5X3Meta?.path ?? "/subscriptions/success",
    meta: successwz2bF1K5X3Meta || {},
    alias: successwz2bF1K5X3Meta?.alias || [],
    redirect: successwz2bF1K5X3Meta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QcN0934MTjMeta?.name ?? "templates-slug",
    path: _91slug_93QcN0934MTjMeta?.path ?? "/templates/:slug()",
    meta: _91slug_93QcN0934MTjMeta || {},
    alias: _91slug_93QcN0934MTjMeta?.alias || [],
    redirect: _91slug_93QcN0934MTjMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexlAeyeUblmFMeta?.name ?? "templates",
    path: indexlAeyeUblmFMeta?.path ?? "/templates",
    meta: indexlAeyeUblmFMeta || {},
    alias: indexlAeyeUblmFMeta?.alias || [],
    redirect: indexlAeyeUblmFMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_939xlOoJAIZCMeta?.name ?? "templates-industries-slug",
    path: _91slug_939xlOoJAIZCMeta?.path ?? "/templates/industries/:slug()",
    meta: _91slug_939xlOoJAIZCMeta || {},
    alias: _91slug_939xlOoJAIZCMeta?.alias || [],
    redirect: _91slug_939xlOoJAIZCMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: my_45templatesOV8yl5mnnvMeta?.name ?? "templates-my-templates",
    path: my_45templatesOV8yl5mnnvMeta?.path ?? "/templates/my-templates",
    meta: my_45templatesOV8yl5mnnvMeta || {},
    alias: my_45templatesOV8yl5mnnvMeta?.alias || [],
    redirect: my_45templatesOV8yl5mnnvMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/templates/my-templates.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93w6vX48wPqZMeta?.name ?? "templates-types-slug",
    path: _91slug_93w6vX48wPqZMeta?.path ?? "/templates/types/:slug()",
    meta: _91slug_93w6vX48wPqZMeta || {},
    alias: _91slug_93w6vX48wPqZMeta?.alias || [],
    redirect: _91slug_93w6vX48wPqZMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsnPTtvoX5ogMeta?.name ?? "terms-conditions",
    path: terms_45conditionsnPTtvoX5ogMeta?.path ?? "/terms-conditions",
    meta: terms_45conditionsnPTtvoX5ogMeta || {},
    alias: terms_45conditionsnPTtvoX5ogMeta?.alias || [],
    redirect: terms_45conditionsnPTtvoX5ogMeta?.redirect,
    component: () => import("/var/www/formbuilder.uptodateconnect.com/client/pages/terms-conditions.vue").then(m => m.default || m)
  }
]