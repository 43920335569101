import revive_payload_client_4sVQNw7RlN from "/var/www/formbuilder.uptodateconnect.com/client/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/formbuilder.uptodateconnect.com/client/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/formbuilder.uptodateconnect.com/client/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/var/www/formbuilder.uptodateconnect.com/client/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/var/www/formbuilder.uptodateconnect.com/client/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/formbuilder.uptodateconnect.com/client/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/formbuilder.uptodateconnect.com/client/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/formbuilder.uptodateconnect.com/client/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/formbuilder.uptodateconnect.com/client/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ZGw8LlWmeq from "/var/www/formbuilder.uptodateconnect.com/client/node_modules/nuxt3-notifications/dist/runtime/plugin.mjs";
import motion_fmZWau4t0U from "/var/www/formbuilder.uptodateconnect.com/client/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/formbuilder.uptodateconnect.com/client/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import amplitude_EZuIopuxgB from "/var/www/formbuilder.uptodateconnect.com/client/plugins/amplitude.js";
import crisp_client_amQNA6tQA4 from "/var/www/formbuilder.uptodateconnect.com/client/plugins/crisp.client.js";
import sentry_PZVGm6LH3G from "/var/www/formbuilder.uptodateconnect.com/client/plugins/sentry.js";
import vue_confetti_client_S5fochbKaT from "/var/www/formbuilder.uptodateconnect.com/client/plugins/vue-confetti.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_ZGw8LlWmeq,
  motion_fmZWau4t0U,
  chunk_reload_client_UciE0i6zes,
  amplitude_EZuIopuxgB,
  crisp_client_amQNA6tQA4,
  sentry_PZVGm6LH3G,
  vue_confetti_client_S5fochbKaT
]